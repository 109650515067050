import Layout from "@components/layouts/Layout";
import axios from "axios";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import RenderLoading from "./components/loading";
import CountUp from "react-countup";
import { Link } from "gatsby";
import { toast } from "react-toastify";
import "./styles.css";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
// import cn from "../../utils/cn";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { PiListChecks, PiCalendarCheck } from "react-icons/pi";
// import Confetti from "react-confetti";

// Importamos el idioma español para dayjs y lo activamos
require("dayjs/locale/es");
dayjs.locale("es");

// Activamos plugin de dayjs para mostrar la fecha en formato relativo
const relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

// Fix para que funciona ReactJson
const ReactJsonView = typeof window !== `undefined` ? require("react-json-view").default : null;

// Renderizamos el componente
const Component = ({ pageContext: { item, prev } }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Capturamos la información adicional del post
        async function fetchData() {
            setLoading(true);

            const toastId = toast.loading("Calculando las puntuaciones...", {
                position: "bottom-center",
            });

            const rawData = await getRanking({ item, prev });

            setData(rawData);
            setLoading(false);
            toast.dismiss(toastId);
        }

        fetchData();
    }, [item, prev]);

    return (
        <Layout
            seo={{
                title: item.title,
                description: item.description,
                image: item.image,
            }}
        >
            <div className="py-10 container">
                <Title>{item.title}</Title>
                <Text>{item.description}</Text>
                {item.message && (
                    <Text className="mt-1">
                        {item.message.split("{{email}}").map((part, index) =>
                            index === 0 ? (
                                <React.Fragment key={index}>
                                    {part}
                                    <Link
                                        href="mailto:info@iopos.academy"
                                        className="link text-cpurple font-semibold"
                                    >
                                        info@iopos.academy
                                    </Link>
                                </React.Fragment>
                            ) : (
                                part
                            ),
                        )}
                    </Text>
                )}
            </div>

            {loading && <RenderLoading />}

            {data && (
                <div className="container pb-20 mx-auto space-y-5 lg:space-y-10">
                    <RenderRank
                        data={data?.weekData}
                        title="Ránking de la semana"
                        description="Los mejores de la semana"
                    />

                    <RenderRank
                        data={data?.monthData}
                        title="Ránking del mes"
                        description="Los mejores del mes"
                    />

                    <RenderRank
                        data={data?.yearData}
                        title="Ránking del año"
                        description="Los mejores del año"
                    />

                    {process.env.NODE_ENV === "development" && (
                        <ReactJsonView src={{ loading, item, data }} collapsed={true} />
                    )}
                </div>
            )}
        </Layout>
    );
};

// Renderizamos los datos del ránking
const RenderRank = ({ data, title, description }) => {
    const startAt = dayjs(data?.startDate).format("DD/MM/YYYY");
    const endAt = dayjs(data?.endDate).format("DD/MM/YYYY");

    // Obtenemos las 3 primeras posiciones
    const featuredPositions = data?.ranking?.slice(0, 3);

    return (
        <>
            <div className="lg:bg-gray-100 rounded-md lg:p-10 bg-opacity-75">
                <h1 className="font-bold uppercase text-lg text-gray-800">{title}</h1>
                <p className="text-gray-600">
                    {description}{" "}
                    <span className="text-cpurple font-semibold text-sm">
                        {startAt} - {endAt}
                    </span>
                    .
                </p>

                {!data?.ranking?.length && (
                    <div className="text-xs text-cpurple mt-5">
                        No hay datos en este momento. Vuelve más tarde.
                    </div>
                )}

                {featuredPositions?.length === 3 && (
                    <div className="mt-5 hidden md:block">
                        <Featured data={featuredPositions} />
                    </div>
                )}

                {data?.ranking?.length > 0 && (
                    <div className="flex flex-col md:flex-row gap-5 lg:gap-10 mt-10">
                        <div className="w-full grid grid-cols-1 gap-5 lg:gap-10">
                            {data?.ranking?.map((item, index) => (
                                <RenderRankPosition key={index} data={item} />
                            ))}
                        </div>
                    </div>
                )}

                <div className="text-xs md:text-right mt-5 lg:mt-10 text-ccyan-dark flex flex-col md:flex-row md:items-center md:justify-between gap-3">
                    <div>
                        <strong>Última actualización:</strong>{" "}
                        {dayjs(data?.date).format("DD/MM/YYYY HH:mm:ss")}
                    </div>

                    {data?.refreshAt && (
                        <div>
                            <strong>Próxima actualización:</strong>{" "}
                            {dayjs(data?.refreshAt).format("DD/MM/YYYY HH:mm:ss")}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

// Renderizamos la posición de un usuario
const RenderRankPosition = ({ data }) => {
    return (
        <>
            {/* Desktop */}
            <div className={styles.position.container}>
                <div className="py-2 px-5 text-center font-bold text-lg text-gray-700">
                    {data.position}º
                </div>
                <div className="w-32 h-full pl-5" />
                <div
                    className="absolute rounded-full w-24 h-24 bg-gray-800 border-4 border-white z-[2] shadow"
                    style={{ marginLeft: "55px" }}
                >
                    <img
                        src={data.userAvatar}
                        className="w-full h-full overflow-hidden rounded-full text-center object-cover bg-cindigo text-white flex items-center justify-center uppercase text-xs font-bold"
                        alt={data.userAlias}
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = "https://www.igub2.net/cms/resources/images/avatar.png";
                        }}
                    />
                </div>

                <div className="py-3 px-5 w-full">
                    <div className="font-bold text-lg truncate-1-lines text-gray-700">
                        {data.userAlias}
                    </div>
                    <div className="text-xs text-ccyan-dark truncate-1-lines">
                        <span className="flex items-center gap-1">
                            <PiListChecks className="text-cpurple" /> {data.counter} tests
                            realizado/s.
                        </span>

                        <span className="flex items-center gap-1">
                            <PiCalendarCheck className="text-cpurple" />
                            Último test acabado el {dayjs(data.lastTestAt).format("DD/MM/YY HH:mm")}
                            .
                        </span>
                    </div>
                </div>

                <div className="py-2 pl-5 pr-2 w-40 text-center bg-ccyan-dark text-white h-full flex items-center justify-center">
                    <div>
                        <div className="text-2xl font-bold">
                            <CountUp
                                start={0}
                                end={Math.round(data.totalScore, 0)}
                                duration={5}
                                separator="."
                            />
                        </div>
                    </div>
                </div>

                <div className="bg-ccyan-dark rounded-r-lg h-full flex flex-1 items-center justify-center py-2 pr-5">
                    {data.previousPosition && data.position < data.previousPosition && (
                        <FaArrowUp className="text-green-500 w-3 h-3 opacity-75" />
                    )}

                    {data.previousPosition && data.position > data.previousPosition && (
                        <FaArrowDown className="text-red-500 w-3 h-3 " />
                    )}

                    {data.position === data.previousPosition && (
                        <div className="w-3 h-3 opacity-50"></div>
                    )}
                </div>
            </div>

            {/** Mobile */}
            <div className={styles.position.mobileContainer}>
                <div className="py-2 px-3 text-center font-bold text-base text-gray-700">
                    {data.position}º
                </div>
                <div className="w-20 h-full pl-5" />
                <div
                    className="absolute rounded-full w-12 h-12 bg-gray-800 border-2 border-white z-[2] shadow"
                    style={{ marginLeft: "42px" }}
                >
                    <img
                        src={data.userAvatar}
                        className="w-full h-full overflow-hidden rounded-full text-center object-cover bg-cindigo text-white flex items-center justify-center uppercase text-xs font-bold"
                        alt={data.userAlias}
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = "https://www.igub2.net/cms/resources/images/avatar.png";
                        }}
                    />
                </div>

                <div className="w-full ml-3 mr-2 overflow-hidden">
                    <div
                        title={data.userAlias}
                        className="font-bold text-sm truncate-1-lines text-gray-700"
                    >
                        {data.userAlias}
                    </div>
                    <div className="text-xs text-ccyan-dark truncate-1-lines">
                        <span className="flex items-center gap-1">
                            <PiListChecks className="text-cpurple" /> {data.counter} tests.
                        </span>
                    </div>
                </div>

                <div className="py-2 px-2 text-center bg-ccyan-dark text-white h-full rounded-r">
                    <div className="text-xs">Puntuación</div>
                    <div className="text-xl font-bold truncate-1-lines">
                        <CountUp
                            start={0}
                            end={Math.round(data.totalScore, 0)}
                            duration={5}
                            separator="."
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

// Obtenemos el ránking
const getRanking = async ({ item, prev }) => {
    try {
        const { data } = await axios.post(
            `/.netlify/functions/ranking`,
            {
                appId: item.appId,
                weekDate: prev ? dayjs().subtract(1, "week").format("YYYY-MM-DD") : null,
                monthDate: prev ? dayjs().subtract(1, "month").format("YYYY-MM-DD") : null,
                yearDate: prev ? dayjs().subtract(1, "year").format("YYYY-MM-DD") : null,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        );

        return data;
    } catch (err) {
        console.error(err);
        return null;
    }
};

// Mostramos las 3 primeras posiciones
const Featured = data => {
    const position1 = data.data[0];
    const position2 = data.data[1];
    const position3 = data.data[2];

    return (
        <div className="relative">
            <div className="absolute z-0 top-0 w-full h-full overflow-hidden">
                {/*<Confetti />*/}
            </div>

            <div className="flex justify-center items-center gap-4 lg:p-8 lg:bg-gray-100 rounded-md pt-16 z-10">
                {/* Segunda posición */}
                <div className="bg-white rounded-lg shadow-md p-6 w-64 h-80 relative border border-opacity-25">
                    <img
                        src="/images/icons/position_2.png"
                        alt="Segunda posición"
                        className="w-16 h-16 mx-auto"
                    />

                    <img
                        src={position2.userAvatar}
                        className="mx-auto rounded-full mt-5 shadow w-20 h-20 object-cover"
                        alt={data.userAlias}
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = "https://www.igub2.net/cms/resources/images/avatar.png";
                        }}
                    />

                    <h2 className="font-bold text-xl text-center text-ccyan mt-5">
                        {position2.userAlias}
                    </h2>

                    <div className="text-center">
                        <CountUp
                            className="font-semibold text-2xl text-cpurple"
                            start={0}
                            end={Math.round(position2.totalScore, 0)}
                            duration={5}
                            separator="."
                        />
                    </div>
                </div>

                {/* Primera posición (más grande) */}
                <div className="rounded-lg shadow-md w-72 h-96 -mt-8 relative bg-white overflow-hidden border border-opacity-25">
                    <div className="z-0 w-full h-full absolute"></div>

                    <div className="relative z-10 p-6">
                        <img
                            src="/images/icons/position_1.png"
                            alt="Primera posición"
                            className="w-20 h-20 mx-auto"
                        />

                        <img
                            src={position1.userAvatar}
                            className="mx-auto rounded-full mt-5 shadow w-32 h-32 object-cover"
                            alt={data.userAlias}
                            onError={e => {
                                e.target.onerror = null;
                                e.target.src =
                                    "https://www.igub2.net/cms/resources/images/avatar.png";
                            }}
                        />

                        <h2
                            title={position1.userAlias}
                            className="font-bold text-xl text-center text-ccyan mt-5 truncate-2-lines"
                        >
                            {position1.userAlias}
                        </h2>

                        <div className="text-center">
                            <CountUp
                                className="font-semibold text-3xl text-cpurple"
                                start={0}
                                end={Math.round(position1.totalScore, 0)}
                                duration={5}
                                separator="."
                            />
                        </div>
                    </div>
                </div>

                {/* Tercera posición */}
                <div className="bg-white rounded-lg shadow-md p-6 w-64 h-80 relative border border-opacity-25">
                    <img
                        src="/images/icons/position_3.png"
                        alt="Tercera posición"
                        className="w-16 h-16 mx-auto"
                    />

                    <img
                        src={position3.userAvatar}
                        className="mx-auto rounded-full mt-5 shadow w-20 h-20 object-cover"
                        alt={data.userAlias}
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src = "https://www.igub2.net/cms/resources/images/avatar.png";
                        }}
                    />

                    <h2 className="font-bold text-lg text-center text-ccyan mt-5 overflow-hidden">
                        {position3.userAlias}
                    </h2>

                    <div className="text-center">
                        <CountUp
                            className="font-semibold text-xl text-cpurple"
                            start={0}
                            end={Math.round(position3.totalScore, 0)}
                            duration={5}
                            separator="."
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const styles = {
    position: {
        container:
            "rounded-lg shadow-lg bg-white flex-row items-center relative border border-gray-200 hidden md:flex",
        mobileContainer:
            "rounded-lg md:hidden bg-white shadow flex flex-row items-center relative border",
    },
};

export default Component;
